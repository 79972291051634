/* Reset */
@import 'tailwindcss/base';
@import 'reset';

/* Components */
@import 'tailwindcss/components';
@import 'buttons';
@import 'form';

/* Utilities */
@import 'tailwindcss/utilities';

.text_overflow {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}
.max_one_line_allow {
    -webkit-line-clamp: 1
}

.max_two_lines_allow {
    -webkit-line-clamp: 2
}

.max_three_lines_allow {
    -webkit-line-clamp: 3
}

.max_four_lines_allow {
    -webkit-line-clamp: 4
}

.max_five_lines_allow {
    -webkit-line-clamp: 5
}

::-webkit-scrollbar {
    width: 10px; transition: all 0.4s ease-in;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
